import React, { useEffect, useState } from 'react';
import { connect } from 'dva';
import { Icon, Tooltip, message } from 'antd';

import intl from 'react-intl-universal';
import styles from './InActiveList.less';

const InActiveList = ({ dispatch, guidance }) => {
  const [stores, setStores] = useState([]);
  const [activate, setActivate] = useState([]);
  const [showList, setShowList] = useState(false);

  const operate = item => {
    const val = [];
    if (activate) {
      activate.forEach(item => {
        val.push(item.store_id);
      });
    }
    val.push(item.store_id);
    dispatch({
      type: 'guidance/KeepTheStore',
      payload: {
        data: {
          stores_id: val
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            window.location.href = '/app/'; // 跳转到首页
          }
        }
      }
    });
  };

  useEffect(() => {
    dispatch({
      type: 'guidance/storeList',
      payload: {
        callback: res => {
          if (res.code === 2000) {
            const nonactivated = [];
            const activate = [];

            res.data.forEach(item => {
              item.store_name += '.myshopify.com';
              if (item.hidden) {
                nonactivated.push(item);
              } else {
                activate.push(item);
              }
            });

            if (activate.length === 0 && nonactivated.length !== 0) {
              setShowList(true); // active店铺数为0时，显示列表
            }
            setActivate(activate);
            setStores(nonactivated);
          }
        }
      }
    });
  }, [dispatch]);

  return (
    showList && (
      <div className={styles.below}>
        <div className={styles.thick}>{intl.get('pricing.InactiveStore')}</div>
        <div className={styles.storeListBox}>
          <div className={styles.storeList}>
            <p>
              <span className={styles.storeListTitle}>
                {intl.get('setting.account.store_name')}
              </span>
            </p>
            {stores && stores.length == 0 ? (
              <p className={styles.noData}>{intl.get('import_list.no_store')}</p>
            ) : (
              stores &&
              stores.map(item => {
                return (
                  <p key={item.store_id}>
                    <span>
                      {item.status === 1 ? (
                        item.store_name
                      ) : (
                        <div className={styles.red}>
                          {item.store_name}
                          <Tooltip
                            title={intl.get(
                              'setting.account.shopify_stores_re_authorized'
                            )}
                          >
                            <Icon type="question-circle" />
                          </Tooltip>
                        </div>
                      )}
                    </span>
                    <span>
                      {!item.disabled ? (
                        <a
                          disabled={item.disabled}
                          className={styles.updateShopifyStore}
                          onClick={() => operate(item)}
                          role="button"
                          tab-index="0"
                        >
                          {intl.get('pricing.Activate')}
                        </a>
                      ) : (
                        <a disabled className={styles.updateShopifyStore}>
                          {intl.get('pricing.Inactivated')}
                        </a>
                      )}
                    </span>
                  </p>
                );
              })
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default connect(({ guidance }) => ({
  guidance
}))(InActiveList);
