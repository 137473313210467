import React from 'react';
import { connect } from 'dva';
import { routerRedux, Link } from 'dva/router';
import { Button, Select, Input, Modal } from 'antd';

import intl from 'react-intl-universal';
import NewBindWrapper from './NewBindWrapper'

import logo from 'assets/svg/logo.svg';
import styles from './LinkStore.less';

import { domainRegexp } from '../../utils/constants';
import RegisterProcess from './RegisterProcess';
import BindWrapper from './BindWrapper';
import InActiveList from './InActiveList';

import TextField from '@material-ui/core/TextField';

import './LinkStore.css';
import { GuildeAction } from 'actions'
import OblerloGuide from '../../components/OblerloGuide'
import Media from 'react-media';
import MobileHeader from 'routes/Pricing/Mobile/Header';

const { Option } = Select;

@connect(({ auth, loading, login }) => ({
  auth,
  loading: loading.models.auth,
  userInfo: login.userInfo,
  addLoading: loading.effects['auth/createShopfiy']
}))
class LinkStore extends React.PureComponent {
  initCheck = false; // 保证只执行一次

  shopName = '';

  wrongTip = '';

  constructor(props) {
    super(props);
    this.state = {
      showWrongTip: false,
      showModal:false
    };
  }

  componentDidMount() {
    const { userInfo } = this.props;

    if (userInfo && !this.initCheck) {
      this.initCheck = true;
      if (userInfo.status === 0) {
        this.props.dispatch(routerRedux.push('/bind/platform'));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { userInfo } = this.props;

    if (userInfo && userInfo !== prevProps.userInfo && !this.initCheck) {
      this.initCheck = true;
      if (userInfo.status === 0) {
        this.props.dispatch(routerRedux.push('/bind/platform'));
      }
    }
  }

  changeShopName = e => {
    const value = e.target.value.trim();
    this.shopName = value;

    // 当清空时
    if (!value) {
      this.wrongTip = '';
      this.setState({ showWrongTip: false });
      return;
    }

    if (!domainRegexp.test(value)) {
      this.wrongTip = intl.get('linkStore.valid_domain_msg');
      this.setState({ showWrongTip: true });
    } else {
      this.wrongTip = '';
      this.setState({ showWrongTip: false });
    }
  };

  addStore = () => {
    if (!this.shopName) {
      return;
    }
    // this.props.dispatch(routerRedux.push('/bind/loading'));
    window.dataLayer.push({
      event: 'registerCustomEvent',
      type: 'addStore',
      position: 'linkStore',
      payload: this.shopName
    });

    this.props.dispatch({
      type: 'auth/createShopfiy',
      payload: {
        data: {
          shop_name: this.shopName
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            if (d.msg === 'bind store quota err') {
              localStorage.setItem('passivity', 'true');
              //  跳转订阅 && 弹窗拦截
              window.EVENT.emit(GuildeAction.TurnPlan_Pop, `s-${Date.now()}`, true);
              //  self.location.href = '/app/pricing?select=1';
            }else{
              window.location.href = d?.data?.url || '/';
            }
          } else {
            if (d.msg == 'store has bound') {
              this.wrongTip = intl.get('linkStore.add_store_already');
            } else {
              this.wrongTip = d.msg || intl.get('linkStore.add_store_already');
            }
            this.setState({
              showWrongTip: true
            });
          }
        }
      }
    });
  };

  // 店铺有没有这个店铺
  hasOld = () => {
    if (!this.shopName || !domainRegexp.test(this.shopName)) {
      return;
    }

    let userInfoStoresHasStore = false;

    this.props.userInfo.stores.forEach(item => {
      if (item.shop_name === this.shopName) {
        userInfoStoresHasStore = true;
      }
    });

    if (!userInfoStoresHasStore) {
      this.addStore();
    } else {
      this.wrongTip = intl.get('linkStore.add_store_err');
      this.setState({
        showWrongTip: true
      });
    }
  };

  render() {
    const { addLoading, userInfo, isMobile } = this.props;
    const { showWrongTip } = this.state;

    return (
      <>
      {
        isMobile ? 
        <div className={styles.container}>
          <MobileHeader/>
          <div className={styles.newContainer}>
          {/* <RegisterProcess /> */}
          <div className={styles.prsbox}>
            <div className="prsl">
              <div>
                <p className="platFormLink">
                  {/* {intl.get('linkStore.website_platform')} */}
                  Step 1. Link Your Store
                </p>
                {/* <Select defaultValue="Shopify">
                  <Option value="Shopify">Shopify</Option>
                </Select> */}
              </div>
              <div >
                <p className="platFormLinkStore">
                  {intl.get('linkStore.website_domain')} <span> (Required)</span>
                </p>
                <div className={showWrongTip ? 'inputGroudD act' : 'inputGroudD'}>
                  <TextField
                    variant="outlined"
                    label={intl.get('linkStore.domain_placeholder')}
                    onChange={this.changeShopName}
                    style={{width:'60vw'}}
                  />
                  <span className="sopI">{intl.get('linkStore.addonAfter')}</span>
                </div>
                {showWrongTip ? (
                  <p className='wrongTip'>{this.wrongTip}</p>
                ) : null}
              </div>
              <div className={styles.btnLine}>
                <Button type="large" data="dx" onClick={this.hasOld} loading={addLoading} className={styles.addstore}>
                  {intl.get('linkStore.add_store')}
                </Button>

                {userInfo.stores && userInfo.stores.length > 0 ? (
                  <a href="/app/settings?id=3">
                    {intl.get('linkStore.go_account')}
                  </a>
                ) : null}

                {userInfo.stores && userInfo.stores.length === 0 ? (
                  <Link
                    to="/bind/platform"
                    track-type="reselectPlatform"
                    track-name="register"
                  >
                    Reselect platform
                  </Link>
                ) : null}
                <InActiveList />
              </div>
            </div>
          </div>
        </div>
        </div>
        :
      <NewBindWrapper bind="linkStore">
        <div className="newContainer">
          {/* <RegisterProcess /> */}
          <div className="prsbox">
            <div className="prsl">
              <div>
                <p className="platFormLink">
                  {/* {intl.get('linkStore.website_platform')} */}
                  Step 1. Link Your Store
                </p>
                {/* <Select defaultValue="Shopify">
                  <Option value="Shopify">Shopify</Option>
                </Select> */}
              </div>
              <div >
                <p className="platFormLinkStore">
                  {intl.get('linkStore.website_domain')} <span> (Required)</span>
                </p>
                <div className={showWrongTip ? 'inputGroudD act' : 'inputGroudD'}>
                  <TextField
                    variant="outlined"
                    label={intl.get('linkStore.domain_placeholder')}
                    onChange={this.changeShopName}
                  />
                  <span className="sopI">{intl.get('linkStore.addonAfter')}</span>
                </div>
                {showWrongTip ? (
                  <p className='wrongTip'>{this.wrongTip}</p>
                ) : null}
              </div>
              <div className='btnLine'>
                <Button type="primary" data="dx" onClick={this.hasOld} loading={addLoading}>
                  {intl.get('linkStore.add_store')}
                </Button>

                {userInfo.stores && userInfo.stores.length > 0 ? (
                  <a href="/app/settings?id=3" style={{width:'215px'}}>
                    {intl.get('linkStore.go_account')}
                  </a>
                ) : null}

                {userInfo.stores && userInfo.stores.length === 0 ? (
                  <Link
                    to="/bind/platform"
                    track-type="reselectPlatform"
                    track-name="register"
                  >
                    Reselect platform
                  </Link>
                ) : null}
                <InActiveList />
              </div>
            </div>
            <div>
              <div className="modal_img" onClick={()=>{
                this.setState({
                  showModal:true
                })
              }}>
                  <img src={require('../../assets/newBing/link2.png')}></img>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.showModal}
          width={970}
          closable={true}
          footer={null}
          onCancel={()=>{
            this.setState({
              showModal:false
            })
          }}
          >
            <img style={{width:'100%'}} src={require('../../assets/newBing/link2.png')}></img>
        </Modal>
        <OblerloGuide />
      </NewBindWrapper>
      }
      </>
    );
  }
}

export default (props => (
  <Media
    queries={{
      small: '(max-width: 599px)',
    }}
  >
    {matches => (
      <LinkStore
        {...props}
        isMobile={matches.small}
      />
    )}
  </Media>
));